.w-pagination-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.w-pagination-previous {
  display: block;
  color: #333;
  font-size: 14px;
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-right: 20px;
  padding-left: 20px;
  background-color: #fafafa;
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-top-color: #ccc;
  border-right-color: #ccc;
  border-bottom-color: #ccc;
  border-left-color: #ccc;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
}

.w-pagination-previous-icon {
  margin-right: 4px;
}

.w-page-count {
  width: 100%;
  text-align: center;
  margin-top: 20px;
}

.w-pagination-next {
  display: block;
  color: #333;
  font-size: 14px;
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-right: 20px;
  padding-left: 20px;
  background-color: #fafafa;
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-top-color: #ccc;
  border-right-color: #ccc;
  border-bottom-color: #ccc;
  border-left-color: #ccc;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
}

.w-pagination-next-icon {
  margin-left: 4px;
}

.w-layout-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}

body {
  font-family: Karla, sans-serif;
  color: #2e4a5f;
  font-size: 16px;
  line-height: 1.5;
}

h1 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 38px;
  line-height: 1;
  font-weight: 700;
}

h4 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: Karla, sans-serif;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.02em;
}

a {
  text-decoration: underline;
}

.section {
  padding-top: 6em;
}

.section.section--hero {
  min-height: 200px;
}

.section.section--bg {
  background-color: #ebf0f7;
}

.section.section--bg.section--last {
  padding-bottom: 6em;
}

.container {
  width: 100%;
  max-width: 1366px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-left: 50px;
}

.container.container--nav {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.nav {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100px;
  border-bottom: 1px solid #ebf0f7;
  background-color: #fff;
}

.hero-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 2em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.nav-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.menu-button {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.brand {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.hero-search {
  position: relative;
  bottom: -35px;
  z-index: 1;
  max-width: 650px;
  margin-right: auto;
  margin-left: auto;
}

.article-search__wrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 70px;
  margin-bottom: 0px;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.search__bar {
  width: 100%;
  height: 70px;
  padding-right: 120px;
  padding-left: 70px;
  border-style: solid;
  border-width: 1px;
  border-color: #a3afbd;
  border-radius: 35px;
  -webkit-transition: border-color 400ms cubic-bezier(.23, 1, .32, 1), box-shadow 400ms cubic-bezier(.23, 1, .32, 1);
  transition: border-color 400ms cubic-bezier(.23, 1, .32, 1), box-shadow 400ms cubic-bezier(.23, 1, .32, 1);
  font-size: 1.2em;
}

.search__bar:hover {
  border-color: #408cff;
}

.search__bar:focus {
  box-shadow: 1px 1px 20px 0 rgba(0, 0, 0, 0.12);
}

.search__button {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0px;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 120px;
  margin: 1px;
  padding-right: 20px;
  padding-left: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 35px;
  background-color: transparent;
  -webkit-transition: all 400ms cubic-bezier(.23, 1, .32, 1);
  transition: all 400ms cubic-bezier(.23, 1, .32, 1);
  color: #a3afbd;
  text-decoration: none;
}

.search__button:hover {
  background-color: #ebf0f7;
  color: #2e4a5f;
}

.search__icon {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 70px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #408cff;
}

.fa {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 1em;
  height: 1em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.hero-title {
  color: #408cff;
  font-size: 50px;
  letter-spacing: -0.03em;
}

.articles__wrapper {
  display: -ms-grid;
  display: grid;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  grid-auto-columns: 1fr;
  grid-column-gap: 2em;
  grid-row-gap: 2em;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.article-image__wrap {
  position: relative;
  height: 250px;
}

.article-image {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  width: 100%;
  height: 100%;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/img/example-bg.png');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-filter: brightness(95%) contrast(90%);
  filter: brightness(95%) contrast(90%);
}

.article-content__wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 120px;
  padding: 0.9em 1.3em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  color: #2e4a5f;
}

.article-title {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #2e4a5f;
}

.article-image__overlay {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  background-color: #2e4a5f;
  opacity: 0.08;
  -webkit-transition: opacity 400ms cubic-bezier(.23, 1, .32, 1);
  transition: opacity 400ms cubic-bezier(.23, 1, .32, 1);
}

.article-image__overlay:hover {
  opacity: 0;
}

.article-author {
  z-index: 1;
  margin-bottom: 0.4em;
  color: #a3afbd;
  font-size: 14px;
  line-height: 1;
}

.article__wrap {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-style: solid;
  border-width: 1px;
  border-color: #d5dae2;
  border-radius: 22px;
  background-color: #fff;
  box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.1);
  -webkit-transition: border-color 400ms cubic-bezier(.23, 1, .32, 1), -webkit-transform 400ms cubic-bezier(.23, 1, .32, 1);
  transition: border-color 400ms cubic-bezier(.23, 1, .32, 1), -webkit-transform 400ms cubic-bezier(.23, 1, .32, 1);
  transition: transform 400ms cubic-bezier(.23, 1, .32, 1), border-color 400ms cubic-bezier(.23, 1, .32, 1);
  transition: transform 400ms cubic-bezier(.23, 1, .32, 1), border-color 400ms cubic-bezier(.23, 1, .32, 1), -webkit-transform 400ms cubic-bezier(.23, 1, .32, 1);
  color: #333;
  text-decoration: none;
}

.article__wrap:hover {
  border-color: #408cff;
  -webkit-transform: translate(0px, -3px);
  -ms-transform: translate(0px, -3px);
  transform: translate(0px, -3px);
}

.article-content__meta {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 8px;
  padding-top: 12px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-top: 1px solid #ebf0f7;
  color: #a3afbd;
  font-size: 14px;
  line-height: 1;
}

.country-filters {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 32px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.filter-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 44px;
  margin: 4px;
  padding-right: 18px;
  padding-left: 18px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 22px;
  background-color: #a3afbd;
  -webkit-transition: all 400ms cubic-bezier(.23, 1, .32, 1);
  transition: all 400ms cubic-bezier(.23, 1, .32, 1);
  color: #fff;
  line-height: 1;
  text-decoration: none;
}

.filter-button:hover {
  padding-bottom: 4px;
  background-color: #2e4a5f;
}

.filter-button.w--current {
  background-color: #408cff;
}

.filter-button.active {
  position: relative;
  padding-right: 44px;
  background-color: #408cff;
}

.pagination {
  margin-top: 4%;
  margin-right: auto;
  margin-left: auto;
}

.pagination__inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.previous {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 44px;
  margin-left: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-decoration: none;
}

.page-count {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.next {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 44px;
  margin-right: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-decoration: none;
}

.icon {
  margin-left: 11px;
}

.icon-2 {
  margin-right: 12px;
}

.filters__wrapper {
  display: -ms-grid;
  display: grid;
  margin-bottom: 3em;
  grid-auto-columns: 1fr;
  grid-column-gap: 2em;
  grid-row-gap: 2em;
  grid-template-areas: "filter-country filter-topic";
  -ms-grid-columns: minmax(200px, 20%) 2em 1fr;
  grid-template-columns: minmax(200px, 20%) 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.article-publisher {
  max-height: 1em;
}

.article-content__title {
  margin-bottom: 0.3em;
}

.pagination-button__wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 150px;
}

.pagination-button__wrap.previous {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.pagination-button__wrap.next {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.article-summary {
  display: none;
}

.article-keywords {
  display: none;
}

.hidden {
  display: none;
}

.article-content__country {
  position: absolute;
  left: 1.3em;
  top: 0.9em;
  right: auto;
  bottom: auto;
  padding-right: 0.4em;
  padding-left: 0.4em;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 14px;
}

.article-content__country:hover {
  background-color: rgba(0, 0, 0, 0.75);
}

.article-loading {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: 370px;
  border-radius: 22px;
  background-color: rgba(0, 0, 0, 0.03);
}

.articles-wrap {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.search__loading_animation {
  width: 70px;
  height: 70px;
  padding: 14px;
}

.search__loading {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 70px;
  height: 70px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.search-icon__bg {
  position: absolute;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: rgba(102, 170, 248, 0.2);
}

.text-field {
  height: 70px;
  margin-bottom: 0px;
  font-size: 1.2em;
}

.article-search {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
}

.dropdown-menu {
  width: 100%;
  margin-left: 0px;
}

.dropdown-toggle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 44px;
  padding: 0px 1.5em;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 22px;
  background-color: #535a63;
  -webkit-transition: all 400ms cubic-bezier(.23, 1, .32, 1);
  transition: all 400ms cubic-bezier(.23, 1, .32, 1);
  color: #fff;
}

.dropdown-toggle:hover {
  background-color: #2e4a5f;
}

.dropdown-list {
  background-color: #fff;
  box-shadow: 7px 7px 20px 0 rgba(0, 0, 0, 0.12);
}

.dropdown-list.w--open {
  margin-top: 8px;
  border-radius: 22px;
}

.search-label {
  margin-bottom: 0.5em;
}

.dropdown-list__inner {
  position: relative;
  overflow: scroll;
  max-height: 250px;
  margin: 11px;
}

.dropdown-list__links {
  padding-right: 12px;
}

.dropdown-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 44px;
  padding: 0px 16px 0px 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 11px;
  -webkit-transition: padding 400ms cubic-bezier(.23, 1, .32, 1);
  transition: padding 400ms cubic-bezier(.23, 1, .32, 1);
  color: #535a63;
}

.dropdown-link:hover {
  padding-left: 16px;
  background-color: #ebf0f7;
  color: #2e4a5f;
}

.dropdown-link.active {
  background-color: #a3afbd;
  color: #fff;
}

.dropdown-link.active:hover {
  background-color: #86909b;
}

.filter__wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.filter-button__wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  margin: -4px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-transition: height 400ms cubic-bezier(.23, 1, .32, 1);
  transition: height 400ms cubic-bezier(.23, 1, .32, 1);
}

.filter-button__wrapper.show-more {
  height: auto;
}

.filter-button__close {
  display: none;
  margin-left: 10px;
  color: #fff;
}

.filter-button__close.active {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 44px;
  height: 44px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.topic-filter__show-more {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 44px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.topics-toggle--show-less {
  display: none;
  height: 2em;
  padding-right: 12px;
  padding-left: 12px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 22px;
  text-decoration: underline;
  cursor: pointer;
}

.topics-toggle--show-less:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.topics-toggle--show-less.show-more {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.topics-toggle--show-less.show-all.hide {
  display: none;
}

.topics-toggle--show-less.show-fewer {
  display: none;
}

.topics-toggle--show-less.show-fewer.show {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.show-more__button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 44px;
  padding-right: 18px;
  padding-left: 18px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 22px;
  -webkit-transition: all 400ms cubic-bezier(.23, 1, .32, 1);
  transition: all 400ms cubic-bezier(.23, 1, .32, 1);
  color: #86909b;
  cursor: pointer;
}

.show-more__button:hover {
  background-color: #a3afbd;
  color: #fff;
}

.topic-filters__show-less {
  display: none;
  height: 44px;
  padding-right: 10px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.topic-filters__show-less.show-more {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.filter__show-more {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.topics-toggle--show-all {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 32px;
  padding-right: 12px;
  padding-left: 12px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 22px;
  text-decoration: underline;
  cursor: pointer;
}

.topics-toggle--show-all:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.topics-toggle--show-all.show-more {
  display: none;
}

.topics-toggle--show-all.show-all.hide {
  display: none;
}

.topics-toggle--show-all.show-fewer {
  display: none;
}

.topics-toggle--show-all.show-fewer.show {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.filter-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 44px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.articles-found {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.pagination-inner {
  display: -ms-grid;
  display: grid;
  max-width: 960px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-areas: "previous-page page-number next-page";
  -ms-grid-columns: 1fr 16px 3fr 16px 1fr;
  grid-template-columns: 1fr 3fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.pagination-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 44px;
  padding-right: 18px;
  padding-left: 18px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 22px;
  background-color: #535a63;
  color: #fff;
  text-decoration: none;
}

.pagination-button:hover {
  background-color: #2e4a5f;
}

.pagination-button.pagination-button--disabled {
  background-color: #a3afbd;
  color: #fff;
}

.articles-loading__grad {
  position: absolute;
  width: 100%;
  height: 50%;
  background-image: -webkit-gradient(linear, left bottom, left top, from(transparent), color-stop(50%, rgba(0, 0, 0, 0.05)), to(transparent));
  background-image: linear-gradient(0deg, transparent, rgba(0, 0, 0, 0.05) 50%, transparent);
}

.articles-loading__wrapper {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 2em;
  grid-row-gap: 2em;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.articles-loading__wrapper.hidden {
  display: none;
}

.text-field-2 {
  min-height: 62px;
  margin-bottom: 0px;
  border-style: none none solid;
  border-width: 1px;
  border-color: #000 #000 hsla(0, 0%, 100%, 0.13);
  background-color: transparent;
  font-size: 16px;
}

.social-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 55px;
  height: 55px;
  padding: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.footer-link {
  display: block;
  margin-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
  color: #86909b;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
  cursor: pointer;
}

.footer-link:hover {
  opacity: 0.5;
}

.footer-bottom {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 2em;
  padding-top: 2em;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.footer-grid {
  grid-column-gap: 2em;
  grid-row-gap: 2em;
  -ms-grid-columns: 0.75fr 1.75fr;
  grid-template-columns: 0.75fr 1.75fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  color: #535a63;
}

.footer-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.form-block {
  margin-top: 40px;
}

.footer-title-button {
  margin-right: 20px;
  color: #fff;
  font-size: 22px;
  line-height: 1.4;
  font-weight: 400;
}

.form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.button {
  padding: 20px 30px;
  border-radius: 10px;
  background-color: #ffd2dd;
  color: #5e4dcd;
  font-size: 16px;
}

.footer-title {
  margin-bottom: 22px;
  color: #408cff;
  font-size: 17px;
}

.container-2 {
  width: 100%;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
}

.secondary-link {
  display: block;
  margin-right: 32px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
}

.submit-button {
  min-height: 62px;
  min-width: 62px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background-color: #ffd2dd;
  color: #5e4dcd;
  font-size: 17px;
}

.footer {
  padding: 132px 3%;
  background-color: #1e2833;
  color: #535a63;
}

.footer-top {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 40px;
  padding-bottom: 60px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
}

.footer-column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.footer-column.horizontal {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.footer-logo {
  width: 230px;
}

.header-logo {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 20px;
  letter-spacing: 0.2em;
}

.text--primary {
  color: #408cff;
}

.footer-lgoo {
  margin-top: 0px;
  margin-bottom: 20px;
  color: #fff;
  font-size: 40px;
  letter-spacing: 0.2em;
}

.footer-subtitle {
  color: #2e4a5f;
}

.footer-link {
  display: inline-block;
  margin-right: 0px;
  color: #408cff;
}

.footer-link:hover {
  opacity: 1;
  color: #fff;
}

.nav-link {
  border-radius: 100px;
  -webkit-transition: all 400ms cubic-bezier(.23, 1, .32, 1);
  transition: all 400ms cubic-bezier(.23, 1, .32, 1);
  color: #a3afbd;
}

.nav-link:hover {
  background-color: #ebf0f7;
  color: #2e4a5f;
}

.pagination__wrapper {
  margin-top: 3em;
  padding-top: 3em;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.article-loading__animation {
  width: 150%;
  height: 150%;
}

.feedback {
  position: fixed;
  left: auto;
  top: auto;
  right: 4%;
  bottom: 4em;
  z-index: 999;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 8px;
  padding-left: 8px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #ebf0f7;
  border-radius: 100px;
  background-color: #fff;
  box-shadow: 21px 21px 40px 0 rgba(0, 0, 0, 0.12);
}

.feedback.thanks {
  display: none;
  padding-right: 0px;
  opacity: 0;
  cursor: auto;
}

.feedback-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: 44px;
  margin-top: 8px;
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 0.8em 1em 0.8em 1.2em;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100px;
  -webkit-transition: all 400ms cubic-bezier(.23, 1, .32, 1);
  transition: all 400ms cubic-bezier(.23, 1, .32, 1);
  cursor: pointer;
}

.feedback-button:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.feedback-button.feedback-button--positive {
  background-color: #b2ff64;
  color: #456129;
  cursor: pointer;
}

.feedback-button.feedback-button--positive:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1)));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
}

.feedback-button.feedback-button--negative {
  background-color: #fd94a0;
  color: #6d4449;
}

.feedback-button.feedback-button--negative:hover {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1)));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
}

.feedback-text {
  margin-right: 0.5em;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.feedback__dismiss {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 44px;
  height: 44px;
  padding-top: 0.8em;
  padding-bottom: 0.8em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100px;
  background-color: #ebf0f7;
  -webkit-transition: all 400ms cubic-bezier(.23, 1, .32, 1);
  transition: all 400ms cubic-bezier(.23, 1, .32, 1);
  cursor: pointer;
}

.feedback__dismiss:hover {
  background-color: #535a63;
  color: #fff;
}

.fas {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 1em;
  height: 1em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.ccij-logo {
  -webkit-filter: invert(100%);
  filter: invert(100%);
}

.footer-logo {
  width: auto;
  height: 30px;
  margin-right: 2em;
}

.footer-logo.ccij {
  -webkit-filter: invert(100%);
  filter: invert(100%);
}

.footer-logos {
  margin-bottom: 2em;
}

.feedback-message {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: 44px;
  margin-top: 8px;
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 0.8em 1.2em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100px;
  background-color: #408cff;
  color: #fff;
}

.far {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 1em;
  height: 1em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.feedback-buttons__wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: auto;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.pagination-numbers__wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 44px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.pagination__page-value {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 44px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #86909b;
  text-decoration: none;
}

.pagination__page-value:hover {
  border-radius: 100px;
  background-color: #a3afbd;
  color: #fff;
}

.pagination__page-value.pagination__page-value--first {
  margin-right: 12px;
}

.pagination__page-value.pagination__page-value--last {
  min-width: 44px;
  margin-left: 12px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: right;
}

.pagination__page-value.pagination__page-value--current {
  color: #408cff;
  cursor: default;
}

.pagination__page-value.pagination__page-value--current:hover {
  background-color: transparent;
}

.pagination__page-links__wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.text-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.pagination__dots {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 6px;
  margin-left: 6px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #a3afbd;
  letter-spacing: 1px;
}

@media screen and (max-width: 991px) {
  .footer-bottom {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .footer-column {
    padding-right: 20px;
  }
}

@media screen and (max-width: 767px) {
  .footer-bottom {
    margin-top: 40px;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .footer-grid {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }
  .footer-column {
    padding-right: 0px;
  }
}

@media screen and (max-width: 479px) {
  .footer-bottom {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
  }
  .footer-grid {
    -ms-grid-columns: 2fr;
    grid-template-columns: 2fr;
    -ms-grid-rows: auto auto auto auto;
    grid-template-rows: auto auto auto auto;
  }
  .footer-wrapper {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .footer-title-button {
    margin-right: 0px;
    margin-bottom: 20px;
  }
  .footer-top {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .footer-logo {
    margin-bottom: 32px;
  }
}

#w-node-a7d02a662996-ab510243 {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: filter-country;
}

#w-node-9aec25cb5fe8-ab510243 {
  -ms-grid-row: 1;
  -ms-grid-column: 3;
  grid-area: filter-topic;
}

#w-node-209137d78293-ab510243 {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: previous-page;
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-0f88b1f94438-ab510243 {
  -ms-grid-row: 1;
  -ms-grid-column: 3;
  grid-area: page-number;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-160a4f7de596-ab510243 {
  -ms-grid-row: 1;
  -ms-grid-column: 5;
  grid-area: next-page;
  -ms-grid-column-align: end;
  justify-self: end;
}

@media screen and (max-width: 767px) {
  #w-node-b090edba82e0-ab510243 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
  }
}

@media screen and (max-width: 479px) {
  #w-node-b090edba82e0-ab510243 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }
}

